.single__nft-img {
    border-radius: 20px;
}

.single__nft-seen span i {
    color: #fff;
    padding: 5px;
    background: #000;
    border-radius: 50px;
    cursor: pointer;
}

.single__nft-seen span,
.single__nft-more span {
    color: #fff;
    font-size: 0.8rem;
}

.single__nft-more span i {
    background: #343444;
    padding: 5px;
    cursor: pointer;
    border-radius: 50px;
}

.nft__creator {
    background: #343444;
    padding: 40px;
    border-radius: 20px;
    width: 100%;
}

.creator__detail p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}

.creator__detail h6 {
    color: #fff;
    margin-bottom: 2;
    font-size: 1.2rem;
    font-weight: 660;
}

.singleNft-btn {
    padding: 8px 25px;
    background: #5142fc;
    border: none;
    outline: none;
    border-radius: 50px;

    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleNft-btn span {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
}

.singleNft-btn:hover {
    background: #fff;
}

.singleNft-btn:hover span {
    color: #5142fc;
}

.singleNft-btn i {
    color: #fff;
}

.singleNft-btn:hover i {
    color: #5142fc;
}

@media only screen and (max-width: 992px) {
    .single__nft__content h2 {
        font-size: 1.3rem;
    }

    .single__nft__content p {
        font-size: 0.8rem;
        line-height: 28px;
    }

    .nft__creator {
        width: 100%;
    }

    .singleNft-btn span {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 576px) {
    .single__nft__content h2 {
        margin-top: 15px;
    }
}
