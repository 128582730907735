.all__category__filter select,
.all__items__filter select,
.filter__right select {
    padding: 8px 25px;
    font-size: 0.9rem;
    color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    background: #343444;
    border-radius: 5px;
}
button {
    white-space: nowrap;
    overflow: hidden;
}
@media only screen and (max-width: 768px) {
    .all__category__filter select,
    .all__items__filter select,
    .filter__right select {
        padding: 5px 20px;
        font-size: 0.6rem;
    }
}
