.header {
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
    background: #14141f;
    box-shadow: 2px 2px 4px -2px #f2ecf3;
}

/* .header__shrink {
  background: #14141f;
  box-shadow: 2px 2px 4px -2px #f3ecec;
} */

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav__list {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    margin-bottom: 0;
}

.nav__item a {
    text-decoration: none;
    color: #fff;
}

.nav__item a:hover {
    color: #cecccc;
    /* background: #4b50e6; */
}

.active {
    color: #488dd6 !important;
}

.logo h2 {
    background: #488dd6;
    background-size: 100% 100%;
    background-clip: text;
    -webkit-text-stroke: 3px transparent;
    -webkit-text-fill-color: #14141f;
    margin-bottom: 0;
}

.nav__right .btn {
    padding: 7px 25px;
    border: 1px solid #5142fc;
    border-radius: 50px;
}

.nav__right .btn a {
    color: #fff;
    text-decoration: none;
    font-size: 0.8rem;
}

.nav__right .btn span i {
    color: #fff;
}

.nav__right .btn a:hover {
    color: #fff;
}

.mobile__menu {
    color: #fff;
    font-size: 1.3rem;
    display: none;
}
.token-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.token-box {
    width: 100%;
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}

.token-label {
    font-weight: bold;
}

.token-value {
    margin-left: 100px;
    font-size: 18px;
    /* You can add more styling as needed */
}
.yf-dialog {
    top: 10%;
    transform: translate(-50%, -50%);
    left: 50%;
    max-width: 100%;
    border-radius: 15px;
    padding: 15px;
    display: inline-block;
    position: fixed;
    -webkit-animation: showAni-data-v-f4dfc91a 0.3s;
    animation: showAni-data-v-f4dfc91a 0.3s;
    background: #13181f;
    border: 1px solid #36383a;
    margin-top: 250px;
    width: 400px;
}
.btn:active {
    transform: scale(0.95); /* Thu nhỏ button khi click */
}
.connect-btn.btn-small {
    min-width: auto !important;
    font-size: 12px;
    height: 28px;
    padding: 0 12px !important;
}
.connect-btn.btn-small {
    min-width: auto !important;
    font-size: 12px;
    height: 28px;
    padding: 0 12px !important;
}
.tal {
    background: #252d35;
    text-align: left;
    border-radius: 10px;
    padding: 10px;
}
.exit-button {
    position: absolute;
    top: 10px; /* Điều chỉnh vị trí từ trên xuống */
    right: 10px; /* Điều chỉnh vị trí từ phải qua */
    width: 20px; /* Kích thước nút Exit */
    height: 20px;
    background-color: #36383a; /* Màu nền */
    color: #ffffff; /* Màu chữ */
    border-radius: 50%; /* Để tạo khung tròn */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Biểu tượng con trỏ khi di chuột qua nút */
}
@media only screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#14141f83, #14141f83);
        z-index: 222222;
        display: none;
    }

    .active__menu {
        display: block;
    }
    .nav__list {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #343444;
        z-index: 333333;
        flex-direction: column;
        padding-top: 40px;
    }

    .mobile__menu {
        display: block;
    }

    .btn {
        padding: 4px 25px !important;
    }

    .btn a {
        font-size: 0.7rem !important;
    }
}
