@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #14141f !important;
    font-family: "Roboto", sans-serif;
}

h1,
h2 {
    color: #fff;
    font-weight: 500;
    font-size: 2rem;
}

p {
    font-weight: 400;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.772);

    line-height: 35px;
}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: unset;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

section,
footer {
    padding: 50px 0px;
}

/* =========== custom scroll-bar ========= */

::-webkit-scrollbar {
    width: 7px;
    background: #343444;
}

::-webkit-scrollbar-thumb {
    border-radius: 2em;
    background: #6c6969;
}

::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}
