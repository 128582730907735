@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #14141f !important;
    font-family: "Roboto", sans-serif;
}

h1,
h2 {
    color: #fff;
    font-weight: 500;
    font-size: 2rem;
}

p {
    font-weight: 400;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.772);

    line-height: 35px;
}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: unset;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

section,
footer {
    padding: 50px 0px;
}

/* =========== custom scroll-bar ========= */

::-webkit-scrollbar {
    width: 7px;
    background: #343444;
}

::-webkit-scrollbar-thumb {
    border-radius: 2em;
    background: #6c6969;
}

::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

/* LoadingSpinner.css */
.loading-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Đen đậm hơn */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Đường viền mảnh hơn và mờ mờ màu */
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite, fadeIn 0.5s ease-out;
            animation: spin 1s linear infinite, fadeIn 0.5s ease-out;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dashboard__section {
    margin-top: 60px;
}

.dashboard__content h2 {
    line-height: 55px;
    font-size: 2.5rem;
}

.dashboard__content h2 span {
    background: #488dd6;
    background-size: 100% 100%;
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-stroke: 3px transparent;
    -webkit-text-fill-color: #14141f;
    margin-left: 7px !important;
    display: inline-block;
}

.dashboard__btns {
    margin-top: 40px;
}

.home__btn {
    border: none;
    outline: none;
    padding: 7px 25px !important;
    border-radius: 50px !important;
    border: 1px solid #5142fc !important;
    font-size: 0.9rem;
    background: transparent;
}
.home__btn:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); /* Thu nhỏ button khi click */
}
.home__btn i {
    color: #fff;
    font-size: 1rem;
}

.home__btn span,
.home__btn a {
    text-decoration: none;
    color: #fff;
}

.dashboard__btns button a:hover {
    color: #fff;
}

.dashboard__detail {
    width: 500px;
    margin-left: auto;
}
.mgt-10.bold {
    width: 100%;
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}
.por.dib.mgt-10 {
    width: 100%;
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}
.por.bold {
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}
.opa-6 {
    opacity: 0.7;
}
.speed-show {
    background: #2a2f35;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 5px;
}
.vertical-children.mgt-10.bold {
    width: 100%;
}
.mgl-5 {
    margin-left: 5px;
}
.bold,
.bold2 {
    font-weight: 700 !important;
}
.mgt-10 {
    margin-top: 5px;
}
.btn-primary.por.mgl-10 {
    min-width: 200px;
    margin-top: 3px;
    border-radius: 10px;
    padding: 5px;
}
.ri-refresh-line {
    color: #c7c3c3;
    font-size: 1rem;
}
.refresh-btn {
    border: 1px solid #969393; /* Định dạng viền cho nút */
    border-radius: 40px; /* Tạo nút hình tròn */
    padding: 1px 6px; /* Điều chỉnh khoảng cách giữa biểu tượng và viền nút */
    background-color: #777474; /* Màu nền của nút */
    cursor: pointer; /* Biểu tượng con trỏ khi di chuột qua nút */
}
.refresh-btn:hover {
    -webkit-transform: scale(1);
            transform: scale(1); /* Phóng to button khi hover */
}

.refresh-btn:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); /* Thu nhỏ button khi click */
}
.refresh-btn i {
    font-size: 1rem; /* Điều chỉnh kích thước của biểu tượng */
    color: #000; /* Điều chỉnh màu của biểu tượng */
}
@media only screen and (max-width: 992px) {
    .dashboard__detail {
        width: 300px;
    }

    .dashboard__section {
        margin-top: 90px;
    }

    .dashboard__content h2 {
        font-size: 1.5rem;
        line-height: 40px;
    }

    .dashboard__content p {
        font-size: 0.8rem;
        line-height: 25px;
    }

    .mint__btn,
    .create__btn,
    .explore__btn {
        padding: 4px 25px !important;
    }

    .create__btn span,
    .mint__btn a,
    .explore__btn a {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 576px) {
    .dashboard__detail {
        width: 100%;
        margin: auto;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 992px) {
    button {
        white-space: nowrap;
    }
    .nft__title {
        font-size: 0.8rem !important;
    }

    .creator__info h6 {
        font-size: 0.6rem !important;
    }

    .creator__info p {
        font-size: 0.7rem !important;
    }

    .bid__btn {
        padding: 4px 20px !important;
        font-size: 0.6rem !important;
    }
    .history__link a {
        font-size: 0.6rem !important;
    }
}

.single__nft__card {
  padding: 20px;
  background: #343444;
  border-radius: 10px;
}

.nft__img img {
  border-radius: 10px;
}

.nft__img {
  margin-bottom: 15px;
}

.nft__title {
  margin-bottom: 15px;
}

.nft__title a {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.nft__title a:hover {
  color: #fff;
}

.creator__img {
  width: 40px;
  height: 40px;
}

.creator__info h6 {
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.772);

  margin-bottom: 0;
  font-weight: 300;
}

.creator__info p {
  font-size: 0.9rem;
  color: #fff;

  margin-bottom: 0;
  font-weight: 500;
}

.bid__btn {
  border: none;
  outline: none;
  padding: 5px 22px;
  background: transparent;
  border: 1px solid #5142fc;
  font-size: 0.9rem;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  background: #5142fc;
}

.bid__btn:hover {
  background: #fff;
  color: #5142fc;
}

.history__link a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.772);

  font-size: 0.9rem;
}

.history__link a:hover {
  color: #fff;
}

.live__auction__top h3 {
  color: #fff;
}

.live__auction__top span a {
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #aeacc2;
}

.all__category__filter select,
.all__items__filter select,
.filter__right select {
    padding: 8px 25px;
    font-size: 0.9rem;
    color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    background: #343444;
    border-radius: 5px;
}
button {
    white-space: nowrap;
    overflow: hidden;
}
@media only screen and (max-width: 768px) {
    .all__category__filter select,
    .all__items__filter select,
    .filter__right select {
        padding: 5px 20px;
        font-size: 0.6rem;
    }
}

.all__category__filter select,
.all__items__filter select,
.filter__right select {
    padding: 8px 25px;
    font-size: 0.9rem;
    color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    background: #343444;
    border-radius: 5px;
}
button {
    white-space: nowrap;
    overflow: hidden;
}
@media only screen and (max-width: 768px) {
    .all__category__filter select,
    .all__items__filter select,
    .filter__right select {
        padding: 5px 20px;
        font-size: 0.6rem;
    }
}

@media only screen and (max-width: 992px) {
    button {
        white-space: nowrap;
    }
    .nft__title {
        font-size: 0.8rem !important;
    }
    .creator__info h6 {
        font-size: 0.6rem !important;
    }

    .creator__info p {
        font-size: 0.7rem !important;
    }

    .list__btn {
        padding: 4px 20px !important;
        font-size: 0.6rem !important;
    }
    .history__link a {
        font-size: 0.6rem !important;
    }
}

.not-found-container {
    text-align: center;
    margin: 50px;
}

.not-found-title {
    color: #fff;
}

.not-found-message {
    font-style: italic;
}

.single__nft-img {
    border-radius: 20px;
}

.single__nft-seen span i {
    color: #fff;
    padding: 5px;
    background: #000;
    border-radius: 50px;
    cursor: pointer;
}

.single__nft-seen span,
.single__nft-more span {
    color: #fff;
    font-size: 0.8rem;
}

.single__nft-more span i {
    background: #343444;
    padding: 5px;
    cursor: pointer;
    border-radius: 50px;
}

.nft__creator {
    background: #343444;
    padding: 40px;
    border-radius: 20px;
    width: 100%;
}

.creator__detail p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}

.creator__detail h6 {
    color: #fff;
    margin-bottom: 2;
    font-size: 1.2rem;
    font-weight: 660;
}

.singleNft-btn {
    padding: 8px 25px;
    background: #5142fc;
    border: none;
    outline: none;
    border-radius: 50px;

    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleNft-btn span {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
}

.singleNft-btn:hover {
    background: #fff;
}

.singleNft-btn:hover span {
    color: #5142fc;
}

.singleNft-btn i {
    color: #fff;
}

.singleNft-btn:hover i {
    color: #5142fc;
}

@media only screen and (max-width: 992px) {
    .single__nft__content h2 {
        font-size: 1.3rem;
    }

    .single__nft__content p {
        font-size: 0.8rem;
        line-height: 28px;
    }

    .nft__creator {
        width: 100%;
    }

    .singleNft-btn span {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 576px) {
    .single__nft__content h2 {
        margin-top: 15px;
    }
}

.single__nft-img {
    border-radius: 20px;
}

.single__nft-seen span i {
    color: #fff;
    padding: 5px;
    background: #000;
    border-radius: 50px;
    cursor: pointer;
}

.single__nft-seen span,
.single__nft-more span {
    color: #fff;
    font-size: 0.8rem;
}

.single__nft-more span i {
    background: #343444;
    padding: 5px;
    cursor: pointer;
    border-radius: 50px;
}

.nft__creator {
    background: #343444;
    padding: 26px;
    border-radius: 20px;
    width: 100%;
}

.creator__detail p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}

.creator__detail h6 {
    color: #fff;
    margin-bottom: 2;
    font-size: 1.2rem;
    font-weight: 660;
}

.singleNft-btn1,
.singleNft-btn2 {
    padding: 8px 25px;
    background: #5142fc;
    border: none;
    outline: none;
    border-radius: 50px;

    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleNft-btn1 span,
.singleNft-btn2 span {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
}

.singleNft-btn1:hover,
.singleNft-btn2:hover {
    background: #fff;
}

.singleNft-btn1:hover span,
.singleNft-btn2:hover span {
    color: #5142fc;
}

.singleNft-btn1 i,
.singleNft-btn2 i {
    color: #fff;
}

.singleNft-btn1:hover i,
.singleNft-btn2:hover i {
    color: #5142fc;
}

@media only screen and (max-width: 992px) {
    .single__nft__content h2 {
        font-size: 1.3rem;
    }

    .single__nft__content p {
        font-size: 0.8rem;
        line-height: 28px;
    }

    .nft__creator {
        width: 100%;
    }
    .singleNft-btn1 span,
    .singleNft-btn2 span {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 576px) {
    .single__nft__content h2 {
        margin-top: 15px;
    }
}

.header {
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
    background: #14141f;
    box-shadow: 2px 2px 4px -2px #f2ecf3;
}

/* .header__shrink {
  background: #14141f;
  box-shadow: 2px 2px 4px -2px #f3ecec;
} */

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav__list {
    display: flex;
    align-items: center;
    grid-column-gap: 2.7rem;
    -webkit-column-gap: 2.7rem;
            column-gap: 2.7rem;
    margin-bottom: 0;
}

.nav__item a {
    text-decoration: none;
    color: #fff;
}

.nav__item a:hover {
    color: #cecccc;
    /* background: #4b50e6; */
}

.active {
    color: #488dd6 !important;
}

.logo h2 {
    background: #488dd6;
    background-size: 100% 100%;
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-stroke: 3px transparent;
    -webkit-text-fill-color: #14141f;
    margin-bottom: 0;
}

.nav__right .btn {
    padding: 7px 25px;
    border: 1px solid #5142fc;
    border-radius: 50px;
}

.nav__right .btn a {
    color: #fff;
    text-decoration: none;
    font-size: 0.8rem;
}

.nav__right .btn span i {
    color: #fff;
}

.nav__right .btn a:hover {
    color: #fff;
}

.mobile__menu {
    color: #fff;
    font-size: 1.3rem;
    display: none;
}
.token-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.token-box {
    width: 100%;
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}

.token-label {
    font-weight: bold;
}

.token-value {
    margin-left: 100px;
    font-size: 18px;
    /* You can add more styling as needed */
}
.yf-dialog {
    top: 10%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    max-width: 100%;
    border-radius: 15px;
    padding: 15px;
    display: inline-block;
    position: fixed;
    -webkit-animation: showAni-data-v-f4dfc91a 0.3s;
    animation: showAni-data-v-f4dfc91a 0.3s;
    background: #13181f;
    border: 1px solid #36383a;
    margin-top: 250px;
    width: 400px;
}
.btn:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); /* Thu nhỏ button khi click */
}
.connect-btn.btn-small {
    min-width: auto !important;
    font-size: 12px;
    height: 28px;
    padding: 0 12px !important;
}
.connect-btn.btn-small {
    min-width: auto !important;
    font-size: 12px;
    height: 28px;
    padding: 0 12px !important;
}
.tal {
    background: #252d35;
    text-align: left;
    border-radius: 10px;
    padding: 10px;
}
.exit-button {
    position: absolute;
    top: 10px; /* Điều chỉnh vị trí từ trên xuống */
    right: 10px; /* Điều chỉnh vị trí từ phải qua */
    width: 20px; /* Kích thước nút Exit */
    height: 20px;
    background-color: #36383a; /* Màu nền */
    color: #ffffff; /* Màu chữ */
    border-radius: 50%; /* Để tạo khung tròn */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Biểu tượng con trỏ khi di chuột qua nút */
}
@media only screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#14141f83, #14141f83);
        z-index: 222222;
        display: none;
    }

    .active__menu {
        display: block;
    }
    .nav__list {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #343444;
        z-index: 333333;
        flex-direction: column;
        padding-top: 40px;
    }

    .mobile__menu {
        display: block;
    }

    .btn {
        padding: 4px 25px !important;
    }

    .btn a {
        font-size: 0.7rem !important;
    }
}

.footer h5 {
  font-size: 1.1rem;
  color: #fff;
}

.footer {
  padding-bottom: 30px;
  background: #000;
}

.list__item {
  background: transparent !important;
  padding-left: 0 !important;
  border: none !important;
}

.list__item a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.772);

  font-size: 0.9rem;
}

.list__item a:hover {
  color: rgba(255, 255, 255, 0.772);
}

.newsletter {
  width: 100%;
  border-radius: 50px;
  color: #fff;
  padding: 7px 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  outline: none;
  font-size: 0.8rem;
  background: #343444;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.772);
}

::placeholder {
  color: rgba(255, 255, 255, 0.772);
}

.social__links span a {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.772);

  font-size: 1.2rem;
  font-weight: 400;
  transition: 0.3s;
}

.social__links span a:hover {
  color: #488dd6;
}

.copyright {
  font-size: 0.8rem;
}

