.dashboard__section {
    margin-top: 60px;
}

.dashboard__content h2 {
    line-height: 55px;
    font-size: 2.5rem;
}

.dashboard__content h2 span {
    background: #488dd6;
    background-size: 100% 100%;
    background-clip: text;
    -webkit-text-stroke: 3px transparent;
    -webkit-text-fill-color: #14141f;
    margin-left: 7px !important;
    display: inline-block;
}

.dashboard__btns {
    margin-top: 40px;
}

.home__btn {
    border: none;
    outline: none;
    padding: 7px 25px !important;
    border-radius: 50px !important;
    border: 1px solid #5142fc !important;
    font-size: 0.9rem;
    background: transparent;
}
.home__btn:active {
    transform: scale(0.9); /* Thu nhỏ button khi click */
}
.home__btn i {
    color: #fff;
    font-size: 1rem;
}

.home__btn span,
.home__btn a {
    text-decoration: none;
    color: #fff;
}

.dashboard__btns button a:hover {
    color: #fff;
}

.dashboard__detail {
    width: 500px;
    margin-left: auto;
}
.mgt-10.bold {
    width: 100%;
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}
.por.dib.mgt-10 {
    width: 100%;
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}
.por.bold {
    height: 40px;
    line-height: 38px;
    padding-left: 15px;
    color: rgb(134, 165, 255);
    font-size: 18px;
    background: rgba(27, 84, 245, 0.1);
    border: 2px solid rgb(27, 84, 245);
    border-radius: 10px;
}
.opa-6 {
    opacity: 0.7;
}
.speed-show {
    background: #2a2f35;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 5px;
}
.vertical-children.mgt-10.bold {
    width: 100%;
}
.mgl-5 {
    margin-left: 5px;
}
.bold,
.bold2 {
    font-weight: 700 !important;
}
.mgt-10 {
    margin-top: 5px;
}
.btn-primary.por.mgl-10 {
    min-width: 200px;
    margin-top: 3px;
    border-radius: 10px;
    padding: 5px;
}
.ri-refresh-line {
    color: #c7c3c3;
    font-size: 1rem;
}
.refresh-btn {
    border: 1px solid #969393; /* Định dạng viền cho nút */
    border-radius: 40px; /* Tạo nút hình tròn */
    padding: 1px 6px; /* Điều chỉnh khoảng cách giữa biểu tượng và viền nút */
    background-color: #777474; /* Màu nền của nút */
    cursor: pointer; /* Biểu tượng con trỏ khi di chuột qua nút */
}
.refresh-btn:hover {
    transform: scale(1); /* Phóng to button khi hover */
}

.refresh-btn:active {
    transform: scale(0.9); /* Thu nhỏ button khi click */
}
.refresh-btn i {
    font-size: 1rem; /* Điều chỉnh kích thước của biểu tượng */
    color: #000; /* Điều chỉnh màu của biểu tượng */
}
@media only screen and (max-width: 992px) {
    .dashboard__detail {
        width: 300px;
    }

    .dashboard__section {
        margin-top: 90px;
    }

    .dashboard__content h2 {
        font-size: 1.5rem;
        line-height: 40px;
    }

    .dashboard__content p {
        font-size: 0.8rem;
        line-height: 25px;
    }

    .mint__btn,
    .create__btn,
    .explore__btn {
        padding: 4px 25px !important;
    }

    .create__btn span,
    .mint__btn a,
    .explore__btn a {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 576px) {
    .dashboard__detail {
        width: 100%;
        margin: auto;
        margin-top: 50px;
    }
}
