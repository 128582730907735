.not-found-container {
    text-align: center;
    margin: 50px;
}

.not-found-title {
    color: #fff;
}

.not-found-message {
    font-style: italic;
}
